import Web3 from "web3";
//web3.js
const contractAddress = "";
const contractABI = [];
var contract = null;
/**
 * @param {Object} callback
 */
function Init(callback) {
  var currentProvider = web3.currentProvider;
  web3 = new Web3();
  web3.setProvider(currentProvider);
  web3.eth.personal.ecRecover(
    "hello",
    "0xe4b27b4ffd34d90f651a420db636419e451f613ca4ab0ea8f0625e82e44d82af65afe851bd050977d9b3642a644fc74eea86ce66d89f4e95c7ddbb4f86e28b311b",
    function (err, result) {
      console.dir(result);
    }
  );
  if (true) {
    return;
  }
}

function PublishContract(callback, errBack, bytecode, abi, args = []) {
  //judge MetaMask
  if (typeof window.ethereum === "undefined") {
    alert("Looks like you need a Dapp browser to get started.");
  } else {
    ethereum
      .enable()
      .catch(function (reason) {
        if (reason === "User rejected provider access") {
        } else {
          alert("There was an issue signing you in.");
        }
        errBack(reason);
      })
      .then(function (accounts) {
        var currentProvider = window.ethereum;
        var web3 = new Web3();
        web3.setProvider(currentProvider);
        contract = new web3.eth.Contract(abi);

        if (accounts.length <= 0) {
          errBack("There was an issue signing you in.");
          return;
        }
        // publish
        contract
          .deploy({
            data: bytecode,
            arguments: args,
          })
          .send(
            {
              from: accounts[0],
              gas: "3000000",
            },
            function (error, transactionHash) {}
          )
          .once("error", function (error) {
            console.log("error", error);
          })
          .once("transactionHash", function (transactionHash) {
            console.log("transactionHash", transactionHash);
          })
          .once("receipt", function (receipt) {
            console.log("receipt", receipt.contractAddress);
          })
          .once("confirmation", function (confirmationNumber, receipt) {
            console.log("confirmation", confirmationNumber);
          })
          .catch((err) => {
            errBack("err on publish");
          })
          .then((instance) => {
            callback(instance.options.address);
            console.log("contract address : ", instance.options.address);
          });
      });
  }
}

async function query(callback, errback, abi, address, method, params) {
  var web3 = getWeb3Instance(true, errback);
  contract = new web3.eth.Contract(abi, address);
  var m = contract.methods[method];
  if (params && params.length > 0) {
    m = m(...params);
  } else {
    m = m();
  }

  try {
    await m
      .call()
      .catch((err) => {
        errback(err);
      })
      .then((result) => {
        callback(result);
      });
  } catch (err) {
    errback(err);
  }
}

function getWeb3Instance(needCreate, errback) {
  var currentProvider;
  // if (typeof window.numscan !== "undefined") {
  //   currentProvider = window.numscan;
  // } else if
  if (typeof window.ethereum !== "undefined") {
    currentProvider = window.ethereum;
  }
  if (needCreate) {
    currentProvider = new Web3.providers.HttpProvider("https://rpc.rchapi.com");
  }
  if (currentProvider) {
    var web3 = new Web3();
    web3.setProvider(currentProvider);
    return web3;
  } else {
    errback("Looks like you need a Dapp browser to get started.");
  }
}

async function enableAndSelectAccount(errback) {
  // var accounts = await web3.eth.getAccounts();
  var currentProvider;
  // if (typeof window.numscan !== "undefined") {
  //   currentProvider = window.numscan;
  // } else
  if (typeof window.ethereum !== "undefined") {
    currentProvider = window.ethereum;
  }
  if (!currentProvider) {
    errback("Looks like you need a Dapp browser to get started.");
    return;
  }
  await currentProvider.enable();
  var web3 = new Web3();
  web3.setProvider(currentProvider);
  var accounts = await web3.eth.getAccounts();
  var account;
  if (!accounts || accounts.length == 0) {
    errback("please connet ad least one Account ");
  } else if (accounts.length == 1) {
    account = accounts[0];
  } else {
    account = accounts[0];
    // await accountsSelect(accounts, (result) => {
    //   account = result;
    // });
  }
  if (account && account.length > 0) {
    return [web3, account];
  } else {
    errback("please connet ad least one Account ");
  }
}

async function sendContractNew(
  callback,
  errback,
  abi,
  address,
  method,
  params,
  value
) {
  var enableResult = await enableAndSelectAccount(errback);
  if (!enableResult) {
    return;
  }
  var web3 = enableResult[0];
  var account = enableResult[1];
  try {
    var contract = new web3.eth.Contract(abi, address);
    var m = contract.methods[method];
    if (params && params.length > 0) {
      m = m(...params);
    } else {
      m = m();
    }
    var pa = { from: account };
    if (value) {
      pa.value = value;
    }
    await m
      .send(pa)
      .catch((err) => {
        errback(err);
      })
      .then((result) => {
        callback(result);
      });
  } catch (error) {
    errback(error);
  }
}

async function sendWithInput(callback, errback, contract, input, value) {
  var enableResult = await enableAndSelectAccount(errback);
  if (!enableResult) {
    return;
  }
  var web3 = enableResult[0];
  var account = enableResult[1];
  try {
    await web3.eth
      .sendTransaction({
        from: account,
        to: contract,
        data: input,
        value: value,
      })
      .then((result) => {
        console.dir(result);
        callback(result);
      })
      .catch((err) => {
        console.dir(err);
        errback(err);
      });
  } catch (error) {
    errback(error);
  }
}

// @delete instead #sendContractNew
async function send(callback, errback, abi, address, method, params) {
  if (typeof window.numscan === "undefined") {
    errback("Looks like you need a Dapp browser to get started.");
  } else {
    try {
      var accounts = await numscan.enable();
      var currentProvider = window.numscan;
      var web3 = new Web3();
      web3.setProvider(currentProvider);
      contract = new web3.eth.Contract(abi, address);
      var m = contract.methods[method];
      if (params && params.length > 0) {
        m = m(...params);
      } else {
        m = m();
      }
      await m
        .send({ from: accounts[0] })
        .catch((err) => {
          errback(err);
        })
        .then((result) => {
          callback(result);
        });
    } catch (error) {
      errback(error);
    }
  }
}

async function signMessage(callback, errback) {
  var enableResult = await enableAndSelectAccount(errback);
  if (!enableResult) {
    return;
  }
  var web3 = enableResult[0];
  var account = enableResult[1];
  try {
    var s = randomString(16);
    await web3.eth.personal.sign(
      web3.utils.fromUtf8(s),
      account,
      function (err, res) {
        callback(err, res, s);
      }
    );
  } catch (err) {
    errback(err);
  }
}
const chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";

function randomString(len) {
  len = len || 32;
  var maxPos = chars.length;
  var pwd = "";
  for (var i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}

//导出相应的方法
export default {
  Init,
  PublishContract,
  query,
  send,
  signMessage,
  sendContractNew,
  sendWithInput,
};
